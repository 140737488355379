import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'token-transfer',
    loadChildren: () => import('./token-transfer/token-transfer.module').then( m => m.TokenTransferModule),
    canActivate: [AuthGuard] // Prevent for signed in users
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then( m => m.LandingPageModule),
  },
  {
    path: 'explorer',
    loadChildren: () => import('./explorer/explorer.module').then( m => m.ExplorerPageModule),
  },
  // {
  //   path: 'cron-bid',
  //   loadChildren: () => import('./cron-bid/cron-bid.module').then( m => m.CronBidPageModule)
  // },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule),
    // ...canActivate(redirectUnauthorizedToLogin),
  },
  // {
  //   path: 'activation/:kodeaktifasi',
  //   loadChildren: () => import('./activation/activation.module').then( m => m.ActivationPageModule)
  // },
  // {
  //   path: 'swap',
  //   loadChildren: () => import('./swap/swap.module').then( m => m.SwapPageModule)
  // },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}