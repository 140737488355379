import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.authService.isAuthenticated) {
      console.log("isAuthenticated");
      return true; // User is authenticated, allow access
    } else {
      console.log("Unauthorize");
      this.router.navigate(['/landing']); // User is not authenticated, redirect to login page
      return false;
    }
  }
}
